import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import App from './App';
import { store, persistor } from './store/configureStore';
import { initAmplitude } from './utils/amplitude';
import { SENTRY_DNS } from './utils/constants/AppConstant';
import { isLocalEnv } from './utils/utils';
import InternalServerError from './components/common/InternalServerError';

// Info:: it must always be false in local environment
// Info:: use REACT_APP_LOCAL_ENV='local' in .env file to set local environment
if (!isLocalEnv) {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') return null;
      if (hint.originalException === 'Network Error') return null;
      if (hint.originalException === 'Request failed with status code 401') return null;
      if (hint.originalException === 'timeout exceeded') return null;
      if (hint.originalException === "Cannot read properties of null (reading 'cssRules')") return null;
      if (hint.originalException === 'Extension context invalidated') return null;
      if (hint.originalException === 'Load failed') return null;
      if (hint.originalException === 'Request aborted') return null;
      return event;
    },
    ignoreErrors: [
      'Timeout',
      'Network Error',
      'Request failed with status code 401',
      'timeout exceeded',
      "Cannot read properties of null (reading 'cssRules')",
      'Extension context invalidated',
      'Load failed',
      'Request aborted',
    ],
  });
}

initAmplitude();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Sentry.ErrorBoundary fallback={InternalServerError} showDialog={false}>
        <App />
      </Sentry.ErrorBoundary>
      <ToastContainer position="top-center" />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
