import React, { useState, useRef, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import httpService from '../../../services/api/httpService';
import { APP_NAME } from '../../../utils/constants/AppConstant';
import path from '../../../routes/path';
import { loginWithHash, schoolLogin } from '../../../services/api/student/authService';
import { setUser } from '../../../store/userSlice';
import useQueryParams from '../../../hooks/useQueryParams';
import { sendAmplitudeData, setAmplitudeUserId } from '../../../utils/amplitude';

const SchoolLogin = (props) => {
  const query = useQueryParams();
  const hash = query.get('hash');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passError, setPassError] = useState('');
  const [pass, setPass] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const emailFocus = useRef(null);
  const location = useLocation();

  useEffect(() => {
    emailFocus.current.focus();
  }, [email]);

  const afterLoginSuccess = (res) => {
    const { access_token, user, type } = res.data.data;
    if (access_token || type === 5 || type === 3) {
      httpService.setToken(access_token);
      dispatch(setUser({ ...user, loggedIn: true, schoolLogin: true }));
      props.history.push(path.school.home);
    } else {
      dispatch(setUser({ ...user, loggedIn: true, schoolLogin: false }));
      if (location.state && location.state.pathname && !location.state.pathname?.includes('logout')) {
        const url = `${location.state.pathname}${location.state?.search || ''}`;
        props.history.push(url);
      } else {
        props.history.push(path.student.home);
      }
    }
    const event = 'Student School Log In Successful';
    const eventProperties = {
      Type: type,
    };
    setAmplitudeUserId(user.star_user_id);
    sendAmplitudeData(event, eventProperties);
  };

  const resetErrors = () => {
    setEmailError('');
    setPassError('');
    setApiError('');
  };

  const SchoolSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    resetErrors();
    try {
      if (validator.isEmpty(email)) {
        setEmailError('Please enter valid email address');
        isError = true;
      }
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setEmailError('Please enter valid email address');
        isError = true;
      }

      if (validator.isEmpty(pass)) {
        setPassError('Please enter valid password');
        isError = true;
      }

      if (isError) return;

      // If need enable recapcha the un comment the below code and pass "recaptchaValue" in captcha:
      // const recaptchaValue = await recaptchaRef?.current?.executeAsync();
      setLoginLoading(true);
      const res = await schoolLogin({
        password: pass,
        email,
        captcha: crypto.randomUUID(),
        captcha_validated: 0,
      });
      setLoginLoading(false);
      afterLoginSuccess(res);
    } catch (error) {
      setApiError(error?.response?.data?.message || 'Something went wrong');
      setLoginLoading(false);
    }
  };

  // login school if hash is present
  useEffect(() => {
    if (hash) {
      const loginStudentWithHash = async () => {
        try {
          setLoginLoading(true);
          const res = await loginWithHash(hash);
          if (res) {
            afterLoginSuccess(res);
          }
        } catch (error) {
          setApiError(error?.response?.data?.message || 'Something went wrong');
        } finally {
          setLoginLoading(false);
        }
      };
      loginStudentWithHash();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <>
      <Helmet title={`Login - School - ${APP_NAME}`}>
        <meta name="robots" content="index,follow" />
        <meta name="description" content="E-Therapy: School Login" />
      </Helmet>
      <Container className="container d-md-flex justify-content-center align-items-center pd-l-15-f pd-r-15-f">
        <div className="schoolloginbox media align-items-stretch justify-content-center mg-l-auto mg-r-auto bd bd-5 rounded-10 shadow-base overflow-hidden wd-sm-400 wd-xl-500 d-flex d-md-flex d-sm-block bg-white">
          <div className="sign-wrapper pd-20 mg-r-auto mg-l-auto pd-md-0 mg-md-30 mg-lg-50 wd-100p wd-sm-350">
            <div className="wd-100p">
              <h3 className="tx-20 tx-md-24 tx-lg-30 tx-color-01 mg-b-5 text-center mg-b-10">School Login</h3>
              <Form className="mg-b-10">
                <Form.Group className="mt-0" controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    Email <sup className="tx-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    ref={emailFocus}
                    autoComplete="off"
                    type="email"
                    placeholder="Enter your Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={loginLoading}
                  />
                  <Form.Text className="tx-13 tx-danger">{emailError}</Form.Text>
                </Form.Group>
                <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>
                    Password <sup className="tx-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="password"
                    placeholder="Enter your Password"
                    onChange={(e) => setPass(e.target.value)}
                    disabled={loginLoading}
                  />
                  <Form.Text className="tx-13 tx-danger">{passError}</Form.Text>
                </Form.Group>
                <div className="invalid-feedback mg-t-10 tx-13 text-center d-block">{apiError}</div>
                <button
                  onClick={SchoolSubmit}
                  disabled={loginLoading}
                  type="submit"
                  className="btn btn-purple btn-block mg-t-20"
                >
                  Login
                </button>
              </Form>
              <div className="divider-text">or</div>
              <Link to="/student/login" className="btn btn-outline-purple btn-block">
                Student Login
              </Link>
              <div className="divider-text">or</div>
              <Link tabIndex="4" className="btn btn-outline-purple btn-block" to="/student/staff/login">
                Staff Login
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SchoolLogin;
