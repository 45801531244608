import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Keyboard from 'react-simple-keyboard';
import validator from 'validator';
import FeatherIcon from 'feather-icons-react';
import { login, loginWithHash } from '../../../services/api/student/authService';
import httpService from '../../../services/api/httpService';
import { setUser } from '../../../store/userSlice';
import path from '../../../routes/path';
import keyboardIcon from '../../../assets/images/keyboard.svg';
import 'react-simple-keyboard/build/css/index.css';
import useQueryParams from '../../../hooks/useQueryParams';
import { sendAmplitudeData, setAmplitudeUserId } from '../../../utils/amplitude';

const useOutsideClick = (keyboardRef, userRef, dobRef, parentRef, callback) => {
  useEffect(() => {
    const onMouseDown = (e) => {
      if (keyboardRef.current) {
        if (!keyboardRef.current.contains(e.target) && userRef.current && !userRef.current.contains(e.target)) {
          callback(false);
        } else if (!keyboardRef.current.contains(e.target) && dobRef.current && !dobRef.current.contains(e.target)) {
          callback(false);
        } else if (
          !keyboardRef.current.contains(e.target) &&
          parentRef.current &&
          !parentRef.current.contains(e.target)
        ) {
          callback(false);
        } else if (!keyboardRef.current.contains(e.target)) {
          callback(false);
        }
      }
    };
    document.addEventListener('mousedown', onMouseDown);

    return () => document.removeEventListener('mousedown', onMouseDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboardRef, userRef, dobRef, parentRef]);
};

function StudentLoginForm(props) {
  const query = useQueryParams();
  const hash = query.get('hash');
  const [loginLoading, setLoginLoading] = useState(false);
  const [initials, setInitials] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [parentName, setParentName] = useState('');
  const [activeField, setActiveField] = useState('Initials');
  const [parentNameType, setparentNameType] = useState('hidden');
  const [showStudent, setShowStudent] = useState('hidden');
  const [showAdditionalInfo, setShowAdditionalInfo] = useState([]);
  const [initalError, setInitialError] = useState('');
  const [dobError, setDobError] = useState('');
  const [parentNameError, setParentNameError] = useState('');
  const [apiError, setApiError] = useState('');
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const [inputs, setInputs] = useState([]);
  const [inputName, setInputName] = useState('default');
  const [layout, setLayout] = useState('shift');
  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const [pattern, setPattern] = useState('');
  const [maxLength, setMaxLength] = useState('');
  const keyboard = useRef();
  const keyboardRef = useRef(null);
  const userInputFocus = useRef(null);
  const dateInputFocus = useRef(null);
  const loginParentNameFocus = useRef(null);
  const [keyboardLayout, setKeyboardLayout] = useState(getDefaultKeyboardLayout());
  const { isStaffLogin } = props;
  const location = useLocation();

  useOutsideClick(keyboardRef, userInputFocus, dateInputFocus, loginParentNameFocus, setKeyboardVisibility);
  useEffect(() => {
    if (inputs.Initials !== undefined) {
      setInitials(inputs.Initials);
    }
    if (inputs.dateofbirth !== undefined) {
      setBirthDate(inputs.dateofbirth);
    }
    if (parentNameType === 'text') {
      if (inputs.parentName !== undefined) {
        setParentName(inputs.parentName);
      }
    }
  }, [inputs, parentNameType]);

  useEffect(() => {
    userInputFocus.current.focus();
  }, [initials]);

  function handleFocusUser() {
    setActiveField('Initials');
    setLayout('shift');
    setKeyboardLayout(getDefaultKeyboardLayout(false));
    userInputFocus.current.focus();
    setPattern(/^[a-zA-Z]+$/);
    setMaxLength(2);
    setKeyboardVisibility((prevState) => !prevState);
  }
  function handleFocusDate() {
    setActiveField('dateofbirth');
    // setKeyboardLayout(getDefaultKeyboardLayout(true));
    setLayout('default');
    setNumberKeyboard();
    dateInputFocus.current.focus();
    setPattern();
    setMaxLength(8);
    setKeyboardVisibility((prevState) => !prevState);
  }

  function getDefaultKeyboardLayout(shouldAddSwitchToNumber) {
    return {
      default: [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{tab} q w e r t y u i o p [ ] \\',
        "{lock} a s d f g h j k l ; ' {enter}",
        '{shift} z x c v b n m , . / {shift}',
        shouldAddSwitchToNumber ? '{switchToNumber} @ {space} .com' : '@ {space} .com',
      ],
      shift: [
        '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
        '{tab} Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : " {enter}',
        '{shift} Z X C V B N M &lt; &gt; ? {shift}',
        shouldAddSwitchToNumber ? '{switchToNumber} @ {space} .com' : '@ {space} .com',
      ],
    };
  }

  const setNumberKeyboard = () => {
    setKeyboardLayout({
      default: ['1 2 3', '4 5 6', '7 8 9', '* 0 #', '{switchToBasic} {bksp}'],
    });
  };

  const onKeyPress = (button) => {
    switch (button) {
      case '{shift}':
      case '{lock}':
        handleShift();
        break;
      case '{switchToNumber}':
        setNumberKeyboard();
        setLayout('default');
        break;
      case '{switchToBasic}':
        setLayout('default');
        setKeyboardLayout(getDefaultKeyboardLayout(true));
        break;
      default:
        break;
    }
  };

  function handleFocusparentName() {
    setActiveField('parentName');
    setLayout('default');
    loginParentNameFocus.current.focus();
    setPattern();
    setMaxLength(50);
    setKeyboardVisibility((prevState) => !prevState);
  }

  const onChangeAll = (value) => {
    const keyInput = Object.keys(value)[0];
    setInputs((prevState) => ({
      ...prevState,
      [keyInput]: value[keyInput],
    }));
  };

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default';
    setLayout(newLayoutName);
  };

  const onChangeInitialsInput = (event) => {
    if (event.target.value.length === 3) return;
    const inputVal = event.target.value.toUpperCase().replace(/[^a-z]/gi, '');

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const onChangeParentNameInput = (event) => {
    const inputVal = event.target.value;

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const onChangeDateInput = (event) => {
    if (event.target.value.length === 9) return;
    const inputVal = event.target.value;

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const getInputValue = (value) => inputs[value] || '';

  const afterLoginSuccess = (res) => {
    const { access_token, user, has_multiple_password, has_parents_name_same, type, students } = res.data.data;
    if (has_multiple_password) {
      setparentNameType('text');
      setApiError(res.data.message);
    }
    if (has_parents_name_same) {
      setShowStudent('text');
      setShowAdditionalInfo(students);
      setApiError(res.data.message);
    } else if (access_token || type === 1 || type === 6) {
      httpService.setToken(access_token);
      const isStaffUser = user?.is_staff;
      dispatch(setUser({ ...user, loggedIn: true, studentLogin: true, isStaffUser }));
      setAmplitudeUserId(user.star_user_id);
      const event = 'Logged In Successfully';
      const eventProperties = {
        'User Type':
          // eslint-disable-next-line no-nested-ternary
          type === 1 ? (isStaffUser ? 'Staff' : 'Student') : type === 6 ? 'FLVS' : '',
      };
      sendAmplitudeData(event, eventProperties);
      if (location.state && location.state.pathname && !location.state.pathname?.includes('logout')) {
        const url = `${location.state.pathname}${location.state?.search || ''}`;
        props.history.push(url);
      } else {
        props.history.push(path.student.home);
      }
    }
  };

  async function StudentLogin(studentId = null) {
    // If need enable recapcha the un comment the below code and pass "recaptchaValue" in captcha:
    // const recaptchaValue = await recaptchaRef?.current?.executeAsync();
    setLoginLoading(true);
    const res = await login({
      password: `${initials}-${birthDate}`,
      captcha: crypto.randomUUID(),
      captcha_validated: 0,
      parent_name: isStaffLogin ? undefined : parentName,
      student_id: isStaffLogin ? undefined : studentId,
      email: isStaffLogin ? parentName : undefined,
      staff: isStaffLogin ? 1 : 0,
    });
    setLoginLoading(false);
    if (res) {
      afterLoginSuccess(res);
    }
  }

  async function handleStudentId(id) {
    StudentLogin(id);
  }

  const studentSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    resetErrors();
    try {
      if (validator.isEmpty(initials)) {
        setInitialError('Initials are required ');
        isError = true;
      }

      if (validator.isEmpty(birthDate)) {
        setDobError('Date of Birth is required');
        isError = true;
      }

      if (validator.isEmpty(parentName) && parentNameType === 'text') {
        if (isStaffLogin) {
          setParentNameError('Email ID is required');
        } else {
          setParentNameError('Parent Name is required');
        }
        isError = true;
      }

      if (isError) return;

      await StudentLogin();
    } catch (error) {
      setLoginLoading(false);
      setApiError(error?.response?.data?.message);
      props.setShowAnotherWayLogin(1);
    }
  };

  // login the user if hash is present
  useEffect(() => {
    if (hash) {
      const loginStudentWithHash = async () => {
        try {
          setLoginLoading(true);
          const res = await loginWithHash(hash);
          if (res) {
            afterLoginSuccess(res);
          }
        } catch (error) {
          setApiError(error?.response?.data?.message || 'Something went wrong');
        } finally {
          setLoginLoading(false);
        }
      };
      loginStudentWithHash();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hash, props.history]);

  function resetErrors() {
    setInitialError('');
    setDobError('');
    setParentNameError('');
  }

  return (
    <>
      <Card className="wd-100p bd-0 border-0">
        <h4 className="tx-color-01 mg-b-5 text-center mg-b-10">{isStaffLogin ? 'Staff' : 'Student'} Login</h4>
        <div className="d-xxs-block d-md-flex mg-b-10">
          <Form>
            <div className="pos-relative">
              <div className="d-xxs-block d-flex mg-b-10">
                <Form.Group>
                  <div className="input-group mg-b-10">
                    <Form.Control
                      tabIndex="1"
                      type="text"
                      placeholder="Initials"
                      maxLength={2}
                      value={getInputValue('Initials')}
                      onChange={onChangeInitialsInput}
                      ref={userInputFocus}
                      autoComplete="off"
                      onFocus={() => {
                        setInputName('Initials');
                        setPattern(/^[a-zA-Z]+$/);
                        setMaxLength(2);
                      }}
                      disabled={loginLoading}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" onClick={handleFocusUser}>
                        <img className="pos-relative t--3" src={keyboardIcon} alt="Keyboard Icon" />
                      </span>
                    </div>
                  </div>
                  <Form.Text className="tx-13 tx-danger">{initalError}</Form.Text>
                </Form.Group>
                <Form.Group className="mg-b-0 mg-l-20 wd-300 wd-xxs-100p mg-xxs-l-0" controlId="birthDate">
                  <div className="input-group mg-b-10">
                    <Form.Control
                      tabIndex="2"
                      type="text"
                      maxLength={8}
                      autoComplete="off"
                      placeholder="Date of Birth (MMDDYYYY)"
                      value={getInputValue('dateofbirth')}
                      ref={dateInputFocus}
                      onChange={onChangeDateInput}
                      onFocus={() => {
                        setInputName('dateofbirth');
                        setMaxLength(8);
                      }}
                      disabled={loginLoading}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" onClick={handleFocusDate}>
                        <img className="pos-relative t--3" src={keyboardIcon} alt="Keyboard Icon" />
                      </span>
                    </div>
                  </div>
                  <Form.Text className="tx-13 tx-danger">{dobError}</Form.Text>
                </Form.Group>
              </div>

              <div className="d-xxs-block  mg-b-10">
                {parentNameType === 'text' && (
                  <Form.Group>
                    <div className="input-group mg-b-10">
                      {isStaffLogin ? (
                        <Form.Control
                          tabIndex="3"
                          type={parentNameType}
                          placeholder="Email ID"
                          autoComplete="off"
                          value={getInputValue('parentName')}
                          ref={loginParentNameFocus}
                          onChange={onChangeParentNameInput}
                          onFocus={() => {
                            setInputName('parentName');
                            setMaxLength();
                          }}
                        />
                      ) : (
                        <Form.Control
                          tabIndex="3"
                          type={parentNameType}
                          placeholder="Parent's First Name"
                          autoComplete="off"
                          value={getInputValue('parentName')}
                          ref={loginParentNameFocus}
                          onChange={onChangeParentNameInput}
                          onFocus={() => {
                            setInputName('parentName');
                            setPattern(/^[a-zA-Z]+$/);
                            setMaxLength();
                          }}
                        />
                      )}
                      <div className="input-group-append">
                        <span className="input-group-text" onClick={handleFocusparentName}>
                          <img className="pos-relative t--3" src={keyboardIcon} alt="Keyboard Icon" />
                        </span>
                      </div>
                    </div>
                    <Form.Text className="tx-13 tx-danger">{parentNameError}</Form.Text>
                  </Form.Group>
                )}
              </div>

              {keyboardVisibility && (
                <div ref={keyboardRef} className="pos-md-absolute z-index-200 wd-100p">
                  <Keyboard
                    display={{
                      '{switchToNumber}': '123',
                      '{switchToBasic}': 'abc',
                      '{bksp}': 'backspace',
                      '{enter}': 'enter',
                      '{shift}': 'shift',
                      '{tab}': 'tab',
                      '{lock}': 'caps',
                      '{space}': 'space',
                    }}
                    keyboardRef={(r) => {
                      keyboard.current = r;
                    }}
                    onChangeAll={onChangeAll}
                    onKeyPress={onKeyPress}
                    inputName={inputName}
                    inputPattern={pattern}
                    maxLength={maxLength}
                    layoutName={layout}
                    onInit={(kb) => kb.setInput(inputs[activeField])}
                    layout={keyboardLayout}
                  />
                  <Button className="btn-xs btn-dark float-right" onClick={() => setKeyboardVisibility(false)}>
                    Close
                  </Button>
                </div>
              )}
            </div>

            <div className="d-xxs-block  mg-b-10">
              {showStudent === 'text' && (
                <>
                  <hr className="mg-t-20" />
                  <p className="text-center tx-14 mg-b-5">Which one is you? Click on the name to login.</p>
                  <p className="text-center mg-0">
                    {showAdditionalInfo.map((resp) => (
                      <Button
                        onClick={() => handleStudentId(resp.id)}
                        className="btn-outline-purple d-block pd-10 bd bd-1 rounded bg-gray-100 tx-black mg-t-10 wd-100p tx-left tx-normal tx-normal"
                      >
                        <div className="d-flex align-items-center student-box">
                          <div className="icon wd-50 ht-50 bd bd-2 mg-r-15 d-inline-flex align-items-center justify-content-center rounded-circle">
                            <FeatherIcon className="d-inline-block" icon="user" />
                          </div>
                          <div className="info">
                            <span className="d-block tx-bold">{resp.name}</span>
                            <span className="d-block tx-medium tx-gray-600">Parent: {resp.parent}</span>
                            <span className="d-block tx-medium tx-gray-600">School: {resp.school}</span>
                          </div>
                        </div>
                      </Button>
                    ))}
                  </p>
                </>
              )}
            </div>
            <div className="invalid-feedback mg-b-10 tx-13 text-center d-block">{apiError}</div>
            <button
              tabIndex="3"
              type="submit"
              onClick={studentSubmit}
              disabled={loginLoading}
              className="btn btn-purple btn-block"
            >
              Login
            </button>

            <div className="divider-text">or</div>
            <Link tabIndex="4" className="btn btn-outline-purple btn-block" to="/student/school/login">
              School Login
            </Link>
            <div className="divider-text">or</div>
            {!isStaffLogin ? (
              <Link tabIndex="4" className="btn btn-outline-purple btn-block" to="/student/staff/login">
                Staff Login
              </Link>
            ) : (
              <Link tabIndex="4" className="btn btn-outline-purple btn-block" to="/student/login">
                Student Login
              </Link>
            )}
          </Form>
        </div>
      </Card>
    </>
  );
}

export default withRouter(StudentLoginForm);
