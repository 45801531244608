import React from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import path from '../path';
import Route from '../CustomRoute';
import Login from '../../screens/student/Login';
import Home from '../../screens/student/Home';
import Schedule from '../../screens/student/Schedule';
import Sessions from '../../screens/student/Sessions';
import Progress from '../../screens/student/Progress';
import Frequency from '../../screens/student/Frequency';
import Documents from '../../screens/student/Documents';
import Profile from '../../screens/student/Profile';
import Logout from '../../components/student/common/Logout';
import SchoolLogin from '../../screens/student/school/Login';
import StaffLogin from '../../screens/student/staff/Login';
import SchoolHome from '../../screens/student/school/Home';
import JoinMeetPage from '../../screens/student/JoinMeet';
import Whereby from '../../screens/student/Whereby';

export default function StudentRoutes() {
  const location = useLocation();
  return (
    <React.Suspense fallback="">
      <Switch>
        <Route path={path.student.login} component={Login} />
        <Route isPrivate path={path.student.home} component={Home} />
        <Route isPrivate path={path.student.joinMeet} component={JoinMeetPage} />
        <Route isPrivate path={path.student.whereby} component={Whereby} />
        <Route isPrivate path={path.school.whereby} component={Whereby} />
        <Route isPrivate path={path.student.schedule} component={Schedule} />
        <Route isPrivate path={path.student.sessions} component={Sessions} />
        <Route isPrivate path={path.student.progress} component={Progress} />
        <Route isPrivate path={path.student.frequency} component={Frequency} />
        <Route isPrivate path={path.student.documents} component={Documents} />
        <Route isPrivate path={path.student.profile} component={Profile} />
        <Route isPrivate path={path.student.logout} component={Logout} />
        <Route path={path.school.login} component={SchoolLogin} />
        <Route path={path.staff.login} component={StaffLogin} />
        <Route isPrivate path={path.school.home} component={SchoolHome} />

        <Redirect
          from={path.root}
          to={{
            pathname: path.student.login,
            state: location,
          }}
        />
      </Switch>
    </React.Suspense>
  );
}
