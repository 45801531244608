/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-cond-assign */
/* eslint-disable global-require */
/* eslint-disable no-promise-executor-return */
import { parseISO, format, parse, addMinutes, startOfMonth, endOfMonth, startOfWeek, endOfWeek } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { PROJECT_NAME } from './constants/AppConstant';

export const isLastItem = (index, length) => index + 1 === length;

export const debounce = (callback, delay = 500) => {
  let timeout;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      callback(...args);
    }, delay);
  };
};

export const throttle = (callback, limit) => {
  let wait = false;
  return (...args) => {
    if (!wait) {
      callback(...args);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
};

export function isWindowsOS() {
  return /Win/.test(navigator.platform);
}

export const removeHtmlTags = (html, removeNewLine = true) => {
  if (!html) {
    return html;
  }

  // Remove HTML tags
  let cleanedStr = html.replace(/<\/?[^>]+(>|$)/g, '');

  if (removeNewLine) {
    // Remove escaped newline characters
    cleanedStr = cleanedStr.replace(/\\n+/g, ' ');

    // Remove actual newline characters
    cleanedStr = cleanedStr.replace(/\n+/g, ' ');

    // Remove carriage return characters
    cleanedStr = cleanedStr.replace(/\r+/g, ' ');
  }

  // Remove literal string "/r/"
  cleanedStr = cleanedStr.replace(/\/r\//g, ' ');

  // Remove literal string "/l/"
  cleanedStr = cleanedStr.replace(/\/l\//g, ' ');

  // Remove special characters (excluding spaces, letters, and numbers)
  cleanedStr = cleanedStr.replace(/[^a-zA-Z0-9\s]/g, '');

  return cleanedStr.trim();
};

export const getTimeSlots = (difference = 5, startTime = '05:00', endTime = '21:00') => {
  const slots = [];

  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const [endHours, endMinutes] = endTime.split(':').map(Number);

  const date = new Date(1970, 0, 1, startHours, startMinutes, 0);
  const endDateTime = new Date(1970, 0, 1, endHours, endMinutes, 0);

  while (date <= endDateTime) {
    let hoursForLabel = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hoursForLabel >= 12 ? 'PM' : 'AM';

    if (hoursForLabel > 12) hoursForLabel -= 12; // Convert from 24-hour to 12-hour format for label
    if (hoursForLabel === 0) hoursForLabel = 12; // Convert midnight from 0 to 12 for label

    const label = `${String(hoursForLabel).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${ampm}`;
    const key = `${String(date.getHours()).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;

    slots.push({ label, key });

    date.setMinutes(date.getMinutes() + difference); // Increment by the given difference
  }

  return slots;
};

export const getTimeKey = (time) => {
  // Parsing the given time string to a Date object
  const parsedTime = parse(time, 'hh:mm a', new Date());

  // Formatting the Date object to the desired key format
  return format(parsedTime, 'HH:mm:ss');
};

export const getStripedString = (string = '') => {
  if (!string) return '';
  // eslint-disable-next-line prefer-regex-literals
  return string.replace(new RegExp('\\\\', 'g'), '');
};

export const formatMoney = (locale = '$', amount = '', minimumFractionDigits = 2) => {
  if (locale === '$' && (amount || amount === 0)) {
    const options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits,
    };

    try {
      // Check if 'narrowSymbol' is supported
      if (Intl.NumberFormat.supportedLocalesOf('en-US', { currencyDisplay: 'narrowSymbol' }).length > 0) {
        options.currencyDisplay = 'narrowSymbol';
      }
    } catch (e) {
      // Safari 14 or older - Ignore and use default options
    }

    return new Intl.NumberFormat('en-US', options).format(amount);
  }

  return amount;
};

const timezoneMapping = {
  'US/Pacific-New': 'America/Los_Angeles',
  // Add more mappings if needed
};

export function getModernTimezone(timezone) {
  return timezoneMapping[timezone] || timezone;
}

export const dateToDisplayDate = ({
  date,
  includeTime = false,
  timezone,
  isUTC = true,
  presentTimezone = 'America/Chicago',
  isWithoutTimezone = false,
}) => {
  let targetDate;

  if (isUTC) {
    // If the date is in UTC format
    targetDate = parseISO(date);
  } else {
    // If the date is in Chicago format
    const chicagoDate = parseISO(date);
    targetDate = zonedTimeToUtc(chicagoDate, presentTimezone);
  }

  // If a timezone is provided, convert the target date to the desired timezone
  if (timezone) {
    targetDate = utcToZonedTime(targetDate, timezone);
  }
  if (isWithoutTimezone) {
    targetDate = getDateWithoutTimezone(date);
  }

  const formatString = includeTime ? 'MM/dd/yyyy hh:mm a' : 'MM/dd/yyyy';
  const formattedDate = format(targetDate, formatString);

  if (new Date(targetDate).getFullYear() <= 0) {
    return '-';
  }
  return formattedDate;
};

export const displayDateToApiDate = (dateStr) => {
  const [month, day, year] = dateStr.split('/');
  return `${year}-${month}-${day}`;
};

export const dateToApiDate = (date) => {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const convertTo12Hour = (timeStr) => {
  let [hour, minute] = timeStr.split(':');
  let period = 'AM';

  hour = parseInt(hour, 10);

  if (hour >= 12) {
    period = 'PM';
    if (hour > 12) hour -= 12;
  } else if (hour === 0) {
    hour = 12;
  }

  return `${hour}:${minute} ${period}`;
};

export const getTimeAsDateObject = (timeStr, weekday) => {
  if (!timeStr) {
    return undefined;
  }

  if (!/^(\d{1,2}):(\d{1,2})(?::\d{1,2})?$/.test(timeStr)) {
    throw new Error('Invalid time format. Expected HH:mm or HH:mm:ss');
  }

  const dayMap = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  const targetDay = dayMap[weekday.toLowerCase()];

  if (targetDay === undefined) {
    throw new Error('Invalid weekday provided');
  }

  const now = new Date();
  const currentDay = now.getDay();

  // Calculate the difference between the current day and the target day
  const dayDifference = targetDay - currentDay;

  // Adjust the date to the target day of the current week
  now.setDate(now.getDate() + dayDifference);

  const [hour, minute, second = '00'] = timeStr.split(':');
  now.setHours(hour, minute, second);

  return now;
};

export const dateToTime = (date) => {
  if (!(date instanceof Date)) {
    throw new Error('Provided value is not a valid Date object');
  }

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const downloadFile = (response) => {
  const filename =
    response.headers['content-disposition']?.split(';')[1]?.split('=')[1]?.replaceAll('"', '') || PROJECT_NAME;
  const type = response.headers['content-type'];
  const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

export const getTempArray = (len = 5) => new Array(len).fill(null).map(() => Math.random());

export const reorderColumn = (columns, columnName, position) => {
  const columnToMove = columns.find((col) => col.name === columnName);
  if (!columnToMove) return columns;

  const newColumns = columns.filter((col) => col.name !== columnName);
  newColumns.splice(position - 1, 0, columnToMove);

  return newColumns;
};

export const reorderColumnsByInstructions = (columns, instructions) =>
  instructions.reduce(
    (accColumns, instruction) => reorderColumn(accColumns, instruction.name, instruction.position),
    columns
  );

// eslint-disable-next-line no-promise-executor-return
export const waitFor = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

export const displayDateToDate = (date, dateFormat) => parse(date, dateFormat || 'MM/dd/yyyy', new Date());

export const isDevelopment = process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development';

export const isLocalEnv = process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOCAL_ENV === 'local';

export const timeToDate = (timeStr) => parse(timeStr, 'HH:mm:ss', new Date());

export const convertTimeToTimezone = ({ time, timezone }) => {
  // Parse the input time (assuming it's in UTC for the current day)
  const currentDay = new Date().toISOString().split('T')[0];
  const utcDate = new Date(`${currentDay}T${time}.000Z`);

  // Convert to desired timezone
  const zonedTime = utcToZonedTime(utcDate, timezone);

  // Format the result
  return format(zonedTime, 'HH:mm:ss', { timeZone: timezone });
};

export const addMinutesToDate = ({ timeString, minutesToAdd }) => {
  // Detect format
  const formatType = timeString.includes('AM') || timeString.includes('PM') ? 'hh:mm aa' : 'HH:mm:ss';

  // Parse the time string into a date object
  const parsedDate = parse(timeString, formatType, new Date());

  // Add minutes
  const newDate = addMinutes(parsedDate, minutesToAdd);

  // Format the result back into a string
  return format(newDate, formatType);
};

export const calculateVisibleDates = (date) => {
  const startOfTheMonth = startOfMonth(date);
  const endOfTheMonth = endOfMonth(date);

  // Adjust these to your locale if necessary
  const startOfTheFirstWeek = startOfWeek(startOfTheMonth);
  const endOfTheLastWeek = endOfWeek(endOfTheMonth);

  return {
    startOfTheMonth,
    endOfTheMonth,
    startOfTheFirstWeek,
    endOfTheLastWeek,
  };
};

export const convertTextToLink = (text) => {
  if (!text) {
    return '';
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
};

export const getTimeFromDate = ({ date, is12Hour = true, timezone }) => {
  const formatString = is12Hour ? 'hh:mm a' : 'HH:mm:ss';

  if (timezone) {
    const zonedTime = utcToZonedTime(date, timezone);
    return format(zonedTime, formatString);
  }

  return format(date, formatString);
};

export const weekendNotAllowedMaxTime = '20:00:00';

export const convertTimezone = ({ fromTimezone, toTimezone, date }) => {
  // Convert the local time to UTC, then to the target timezone
  const utcDate = zonedTimeToUtc(date, fromTimezone);
  return utcToZonedTime(utcDate, toTimezone);
};

/**
 * Returns the short name of the given day.
 *
 * @param {string} day - The day of the week.
 * @returns {string} The short name of the day or 'Invalid day' if the day is not valid.
 */
export const getShortDayName = (day) => {
  const dayMap = {
    sunday: 'Sun',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
  };

  return dayMap[day.toLowerCase()] || 'Invalid day';
};

/**
 * Converts a given date string to PM time format.
 *
 * @param {string} dateString - The date string to convert.
 * @returns {Date} - The converted date in PM time format.
 */
export const convertToPM = (dateString) => {
  const date = new Date(dateString);

  // Check if the time is in AM
  if (date.getHours() < 12) {
    date.setHours(date.getHours() + 12);
  }

  return date;
};

export const isTherapistSystem = () => window.location.pathname.startsWith('/therapist');

/**
 * Retrieves the value of a cookie by its name.
 *
 * @param {string} cookieName - The name of the cookie to retrieve.
 * @returns {string|null} The value of the cookie, or null if the cookie does not exist.
 */
export const getCookieByName = (cookieName) => {
  const cookie = {};
  document.cookie.split(';').forEach((el) => {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });

  return cookie[cookieName] || null;
};

/**
 * Formats a string retrieved from the database.
 *
 * @param {string} str - The string to be formatted.
 * @param {boolean} [addBr=false] - Whether to add `<br />` tags instead of newlines.
 * @returns {string} The formatted string.
 */
export const formatDBString = (str = '', addBr = false) => {
  if (!str) return str;
  let formattedStr = str;
  // remove \r
  formattedStr = formattedStr.replace(/\\r/g, '');

  return formattedStr.replace(/\\n/g, addBr ? '<br />' : '\n');
};

export const getWeekdayFromDate = (date) => {
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return weekdays[date.getDay()];
};

export const getCurrentSchoolYear = (startMonth = 8, endMonth = 7) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // January is 0

  let startYear;
  let endYear;

  if (currentMonth < startMonth) {
    // If the current month is before the start month or after the end month,
    // the school year started last year
    startYear = currentYear - 1;
    endYear = currentYear;
  } else {
    // If the current month is within the school year,
    // the school year starts this year
    startYear = currentYear;
    endYear = currentYear + 1;
  }

  return { startYear, endYear };
};

export const getDateWithoutTimezone = (dateString) => {
  if (!dateString) return undefined;

  let date;

  // Check if the date string is in UTC format (ends with 'Z')
  if (dateString.toString().endsWith('Z')) {
    // Parse as UTC date
    date = utcToZonedTime(parseISO(dateString), 'UTC');
  } else {
    // Parse as local date
    date = new Date(dateString);
  }

  return date;
};

// Function to get the ordinal indicator
export const getOrdinalIndicator = (day) => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
// Date logic
export function isValidDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();

  if (year > 1) {
    return true;
  }
  return false;
}

export const getMonthNumberByName = (monthName) => {
  // Create a date object from the month name
  const date = parse(monthName, 'MMMM', new Date());

  // Get the month number from the date object
  const monthNumber = date.getMonth();

  // Return the month number
  return monthNumber;
};

export const getMonthNameByNumber = (monthNumber) => {
  // Create a date object from the month number
  const date = new Date(2021, monthNumber, 1);

  // Get the month name from the date object
  const monthName = format(date, 'MMMM');

  // Return the month name
  return monthName;
};

export const convertObjectToFormData = (obj, formData = new FormData(), parentKey = '') => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const formKey = parentKey ? `${parentKey}[${key}]` : key;

    if (value && typeof value === 'object' && !(value instanceof File)) {
      convertObjectToFormData(value, formData, formKey);
    } else {
      formData.append(formKey, value);
    }
  });

  return formData;
};

export const getNavigatorInfo = () => {
  const allNavigatorInfo = [];
  for (const n in window.navigator) {
    if (typeof navigator[n] !== 'function' && typeof navigator[n] !== 'object')
      allNavigatorInfo.push({ k: n, data: navigator[n] });
    else if (n === 'connection') {
      const temp_connection = [];
      for (const con in navigator[n]) {
        if (typeof navigator[n][con] !== 'function' && typeof navigator[n][con] !== 'object')
          temp_connection.push({ k: con, data: navigator[n][con] });
      }
      allNavigatorInfo.push({ k: 'connection', data: temp_connection });
    }
  }

  return allNavigatorInfo;
};

export const getBrowserInfo = () => {
  const unknown = 'Unknown';

  // Screen
  let screenSize = '';
  if (window.screen.width) {
    const width = window.screen.width ? window.screen.width : '';
    const height = window.screen.height ? window.screen.height : '';
    screenSize = `${width} x ${height}`;
  }

  // Browser
  const nVer = navigator.appVersion;
  const nAgt = navigator.userAgent;
  let browser = navigator.appName;
  let version = `${parseFloat(navigator.appVersion)}`;
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset;
  let verOffset;
  let ix;

  // Opera
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(verOffset + 5);
  }
  // IE 11
  else if (browser === 'Netscape' && nAgt.indexOf('Trident/') !== -1) {
    browser = 'Microsoft Internet Explorer';
    if ((verOffset = nAgt.indexOf('rv:')) !== -1) {
      version = nAgt.substring(verOffset + 3);
    }
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browser = 'Chrome';
    version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browser = 'Safari';
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
    if (nAgt.indexOf('CriOS') !== -1) {
      browser = 'Chrome';
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browser = 'Firefox';
    version = nAgt.substring(verOffset + 8);
  }
  // Other browsers
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() === browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }
  // Trim the version string
  if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix);

  majorVersion = parseInt(version, 10);
  if (Number.isNaN(majorVersion)) {
    version = `${parseFloat(navigator.appVersion)}`;
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  // Mobile
  const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

  // Cookie
  let { cookieEnabled } = navigator;
  if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
  }

  // OS
  let os = unknown;
  const clientStrings = [
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows ME', r: /Windows ME/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Linux', r: /(Linux|X11)/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
  ];

  for (const cs of clientStrings) {
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  let osVersion = unknown;
  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = 'Windows';
  }

  switch (os) {
    case 'Mac OS X':
      osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
      break;
    case 'Android':
      osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
      break;
    case 'iOS':
      {
        const iOSVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = `${iOSVersion[1]}.${iOSVersion[2]}.${iOSVersion[3] || 0}`;
      }
      break;
    default:
      break;
  }

  const browserInfo = {
    screen: screenSize,
    browser,
    browserVersion: version,
    mobile,
    os,
    osVersion,
    cookies: cookieEnabled,
  };

  return browserInfo;
};
