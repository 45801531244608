export const PROJECT_NAME = 'STAR 3.0';
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const FEEDSPACE_ID_WHEREBY_POST_SESSION = process.env.REACT_APP_FEEDSPACE_ID_WHEREBY_POST_SESSION;
export const FEEDSPACE_ID_GIVE_FEEDBACK = process.env.REACT_APP_FEEDSPACE_ID_GIVE_FEEDBACK;
export const API_STUDENT_PATH = `/student/${API_VERSION}`;
export const API_THERAPIST_PATH = `/${API_VERSION}/therapists`;
export const API_SCHOOL_PATH = `/${API_VERSION}/schools`;
export const API_STUDENTS_PATH = `/${API_VERSION}/students`;
export const API_VERSION_PATH = `/${API_VERSION}`;
export const APP_NAME = process.env.REACT_APP_NAME;
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const USER_TYPES = {
  student: 3,
  therapist: 1,
};
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const IS_DEV = process.env.NODE_ENV === 'development';
export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const LEGACY_SITE_URL = process.env.REACT_APP_LEGACY_SITE_URL;
export const OVERALL_FEEDBACK_FEEDSPACE_ID = process.env.REACT_APP_OVERALL_FEEDBACK_FEEDSPACE_ID;
export const THERAPIST_TITLE_POST_FIX = `- Therapist - ${APP_NAME}`;
export const SERVICE_TYPES = {
  LateCancelSpeech: 25,
  NoShowSpeech: 26,
  ProgressReportsCAVA: 288,
};
export const FEEDSPACE_REPORT_BUG_VIDEO_LINK = process.env.REACT_APP_FEEDSPACE_REPORT_BUG_VIDEO_LINK;
export const VIDEO_TYPES = {
  learnTheBasics: 1,
  howToGuild: 2,
  caSpecialTraining: 3,
};
